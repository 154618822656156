/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, Box, Flex, Text } from "theme-ui";
import Layout from "../components/Layout";
import { titleSx } from "../components/style";

const HowItWorksPage = memo(function HowToPlayPage(): ReactElement {
  return (
    <Layout title="How it works">
      <Flex mx={4}>
        <Box sx={titleSx}>How it works</Box>
      </Flex>
      <Box
        mt={3}
        px={4}
        py={4}
        sx={{ borderRadius: "40px", backgroundColor: "#0B162F" }}
      >
        <Text>
          Dapp Queens was built by a team of blockchain enthusiasts looking to
          create the first decentralized crypto themed fantasy game. Crypto
          tokens can be volatile and unpredictable; over the course of a week,
          token value has the ability to change drastically. With hundreds of
          tokens to choose from, everyone has a chance at choosing a token that
          could grow the most over the same timeframe.
          <br />
          <br />
          We created a smart contract to automatically collect and distrubute
          prize funds to participating players, making the core featues of our
          platform completly decentralized.
          <br />
          <br />
          Token prices are constantly being updated and displayed on our
          Rankings page using Uniswap’s open source API. Player’s choices are
          stored on chain, and automatically ranked based off their tokens.
          <br />
          <br />
          Once the contest ends prize earnings are automatically distributed
          through the smart contract’s resolve function.
          <br />
          <br />
          Have questions? Contact us at{" "}
          <a sx={{ color: "blue.4" }} href="mailto:dappqueens@gmail.com">
            dappqueens@gmail.com
          </a>
          .
        </Text>
      </Box>
    </Layout>
  );
});
export default HowItWorksPage;
